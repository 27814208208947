<template>
  <!-- Ten ekran nie jest obecnie używany. Zamiast tego jego zawartość jest dynamicznie wyświetlana
        na ekranach QuestionMainScreen i QuestionForm za pomocą dyrektyw v-if i v-else -->
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/logo.svg')"
          class="my-3"
          contain
          height="200"
        />
      </v-col>

      <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
        Szukamy dla Ciebie
        <br>przyjemności
        </h1>

        <p class="subheading font-weight-regular">
          W naszej bibliotece
          <br>mamy ich już
        </p>

        <p class="subheading font-weight-regular">
            {{pleasuresNum}}
        </p>

        <v-btn @click="temp()">
        TEMP
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: 'SearchPleasure',
  data: () => ({
    pleasuresNum: 0
  }),
  methods: {
    temp() {
      this.$router.push('/result');
    },

  },
  mounted () {
    axios
      .get('https://fmaprzyjemnoapp.herokuapp.com/pleasures/count/')
      .then(response => (this.pleasuresNum = response.data.count))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
