<template>
  <v-container class="przyjemno-screen">
    <v-row>
      <v-col cols="12">
        <v-img
          class="przyjemno-img-1"
          :src="require('../assets/icons/logo5.svg')"
        />
      </v-col>

      <v-col class="mb-4">
          <h1 class="przyjemno-text" style="font-weight:500; font-size: 26px;">
            Oto i one!

          </h1>

          <p class="przyjemno-text" style="font-size: 19px; font-weight:regular; margin-top:2%;">
            Dwie przyjemności dla Ciebie
            <br>wybierz jedną z nich:
          </p>
          <v-radio-group v-model="radioGroup" class="custom-radio-group">
            <v-expansion-panels>
                <v-expansion-panel
                v-for="(item,i) in pleasures"
                :key="i"
                class='przyjemno-panel'
                active-class="przyjemno-panel"
                @change="() => { changeExpansionStatus(i)}"
                >
                    <v-expansion-panel-header hide-actions>
                      <template>
                        <v-row no-gutters>
                          <v-col cols="11">
                            <p style="margin-bottom:0;">{{i+1}}.</p>
                            <br>
                            <p style="font-weight:550; font-size:24px; margin-bottom:0;">{{item.title}}</p>
                            <br>
                            <span class="header" >{{ !item.expanded ? 'Czytaj więcej' : 'Czytaj mniej' }}</span>
                            <v-icon v-if="!item.expanded">mdi-chevron-down</v-icon>
                            <v-icon v-else>mdi-chevron-up</v-icon>
                          </v-col>
                          <v-col cols="1" style="float:right;">
                            <v-radio
                              :key="i"
                              :value="i"
                              @click.native.stop
                              @click="() => {pleasureChosen=true;}"
                            ></v-radio>
                          </v-col>

                        </v-row>
                      </template>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content style="text-align: justify;">
                        {{item.description}}
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
          </v-radio-group>
        </v-col>
    </v-row>
    <!-- <v-col style="position:fixed; bottom:5%; width:405px; z-index:6; background: #DFD9EB;">
    <v-btn height='50px' class="przyjemno-button" style="margin-bottom:5%;" v-show="pleasureChosen" @click="goToPleasureActions">
    Wybieram
    </v-btn>
    <p class="przyjemno-text" style="margin:auto; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>
    </v-col> -->

    <v-col style="position:fixed; bottom:5%; margin-left:-12px; width:405px; z-index:6; background: linear-gradient(rgba(223,217,235,0.5), rgba(223,217,235,1);">
      <v-btn height='50px' class="przyjemno-button" style="margin-bottom:5%;" v-show="pleasureChosen" @click="goToPleasureActions">
      Wybieram
      </v-btn>
      <p class="przyjemno-text" style="margin:auto; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>
      </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'PleasureScreen',
  data: () => ({
    goBackButton:"<< wracam do początku",
    pleasures:[],
    radioGroup: -1,
    previouslySelected: -1,
    pleasureChosen:false,
  }),
  methods: {
    goToPleasureActions() {
      localStorage.setItem('pleasureChosen',JSON.stringify(this.pleasures[this.radioGroup]))
      this.$router.push('/actions');
    },
    goBackAll() {
      this.$router.push('/');
    },
    changeExpansionStatus(pleasureID){
      this.pleasures[pleasureID].expanded = !this.pleasures[pleasureID].expanded;
            
      for(let i = 0; i<this.pleasures.length; i++){
        if(i != pleasureID){
          this.pleasures[i].expanded = false;
        }
      }
      this.$forceUpdate();
    },
    getPleasure() {
      return (
        localStorage.getItem('pleasureList')
      )
    },
    uncheck: function(val) {
      if (val === this.previouslySelected) {
        this.radioGroup = false;
      }
      this.previouslySelected = this.radioGroup;
      this.pleasureChosen = false;
    },
  },
  mounted () {
    this.pleasures = JSON.parse(this.getPleasure());
    for(let i = 0; i<this.pleasures.length; i++){
      this.pleasures[i].expanded = false;
    }
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.icon {
    order: 0;
}

.header {
    order: 1;
}

.przyjemno-screen{
  background: #DFD9EB;
  overflow-y: auto;
  width:405px;
  height:771px;
}
.przyjemno-panel{
  margin-bottom:10%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.przyjemno-panel:active{
  margin-bottom:10%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.v-expansion-panel>>> .v-expansion-panel__wrap {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.przyjemno-img-1{
  margin-right: 25%;
  margin-left: 25%; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-text-waw{
  font-family: 'League Spartan';
  font-weight: 500;
  text-align: center;
  color: #000000;
  font-size:2.75vw; 
  text-align: left; 
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
.v-icon.v-icon {
  font-size:30px!important;
}
.v-input--selection-controls__input .v-icon {
  font-size:30px!important;
}
.v-radio > .v-input--selection-controls__input > .v-icon.v-icon { font-size:30px!important;width:50px!important;height:50px!important; }

.przyjemno-panel >>> icon { font-size:30px!important;width:50px!important;height:50px!important; }


</style>
<style>
.custom-radio-group i {
  font-size: 30px!important;
  color:black!important;
}
.custom-radio-group label {
  font-size: 14px!important;
}
.custom-radio-group .v-radio {
}
.custom-radio-group [class*="__ripple"] {
}
</style>
