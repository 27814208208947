<template>
  <v-container class='przyjemno-screen' style="padding:0;">
    <v-img
      :src="require('../assets/icons/shapeStart.svg')"
      
    />
    <v-row style="padding:0px; margin-top:-20%;">
      <v-col>
        <v-img
          class="przyjemno-img-2"
          :src="require('../assets/icons/logo_przyjemno04.svg')"
          width="80%"
          style="margin-right:10%; margin-left:10%; margin-top:10%;"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <p class="przyjemno-text">
          Podpowiemy Ci
          <br>dwie przyjemności, które
          <br>możesz dać sobie tu i teraz
        </p>

        <v-btn height='50px' class="przyjemno-button" color="#FFFFFF" @click="goToQuestions">
        Zaczynam
        </v-btn>

        <!-- <v-divider style="margin-top:12%; border-color:black;" ></v-divider> -->

        <v-img
          :src="require('../assets/icons/Line2.svg')"
          style="margin-top:12%; width:60%; height:0.5%; margin-left: auto; margin-right: auto;"
        />

        <p class="przyjemno-text" style="margin-top:12%;">
          Możesz też dodać
          <br>swoją przyjemność
          <br>do wspólnej biblioteki
        </p>

        <v-btn height='50px' class="przyjemno-button" color="#E8E3F2" @click="goToAddPleasureForm">
        Dodaję
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BeginningScreen',
  data: () => ({

  }),
  methods: {
    goToAddPleasureForm() {
      this.$router.push('/add-pleasure');
    },
    goToQuestions() {
      this.$router.push('/question-main');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #C6B9D6;
  overflow: hidden;
  width:405px;
  height:771px;
  max-height:100%;
}

/* .przyjemno-img-1{
  position:absolute;
  margin-right: 0%!important;
  margin-top: 0%!important; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
} */
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
</style>
