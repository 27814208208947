<template>
  <v-container class="przyjemno-screen fill-height">
    <v-row>
        <v-col style="margin-bottom:10%;">
        <h1 class="przyjemno-text" style="font-weight:550; font-size:26px; margin:4%; margin-bottom:10%">
            Jaka jest Twoja ulubiona
            <br>przyjemność?
        </h1>

        </v-col>

        <v-col
        class="mb-5"
        cols="12"
        >
        <v-form
        v-model="form"
        >
          <v-text-field
            class="custom-label-color custom-placeholer-color"
            v-model="pleasureTitle"
            :error=isTitleError
            :error-messages=titleErrorMessage
            maxlength="128"
            outlined
            color="black"
            label="Tytuł Twojej przyjemności"
            @input="()=>{isTitleError=false; titleErrorMessage='';}"
          ></v-text-field>
          <v-textarea
            class="custom-label-color custom-placeholer-color"
            v-model="pleasureDescription"
            :error=isDescriptionError
            :error-messages=descriptionErrorMessage
            maxlength="1024"
            color="black"
            outlined
            label="Opis przyjemności - krok po kroku"
            @input="()=>{isDescriptionError=false; descriptionErrorMessage='';}"
          ></v-textarea>
          <v-btn height='50px' class="przyjemno-button" style="background:#F5F5F5;" @click="goToSubmit">
          Wysyłam
          </v-btn>
        </v-form>
        <v-btn height='50px' class="przyjemno-button" style="background:#E9F1E2;" @click="goToFinal">
        Pomijam
        </v-btn>
        </v-col>

        <v-col
        class="mb-5"
        cols="12"
        >
        <p class="przyjemno-text" style="font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton">
        </p>
        </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
export default {
  name: 'AddPleasure',
  data: () => ({
    goBackButton:"<< wracam do początku",
    form: false,
    pleasureTitle: '',
    pleasureDescription: '',
    rules: {
      required: value => !!value || 'To pole jest wymagane.',
      titleCounter: value => value.length <= 128 || 'Tytuł nie może mieć więcej niż 128 znaków',
      descriptionCounter: value => value.length <= 1024 || 'Opis nie może mieć więcej niż 1024 znaki'
    },
    isTitleError:false,
    isDescriptionError:false,
    titleErrorMessage:'',
    descriptionErrorMessage:'',
  }),
  methods: {
    goToSubmit() {

      this.checkTitleField(this.pleasureTitle)
      this.checkDescriptionField(this.pleasureDescription)

      if((this.pleasureTitle.length <= 128 && this.pleasureTitle.length > 0) && (this.pleasureDescription.length <=1024 && this.pleasureDescription.length > 0)){
        
        this.scoring = {
            "stay_at_home": 0,
            "other_people":  0,
            "body_oriented": 0,
            "intellectual": 0,
            "silent": 0
        }

        this.data = {
          'title':this.pleasureTitle,
          'description':this.pleasureDescription,
          'scoring':this.scoring
        }

        axios.post('https://fmaprzyjemnoapp.herokuapp.com/pleasures/', this.data)
        .then() //response => console.log(response))
        .catch() //error => console.log(error))
                

        this.$router.push('/success-add');
      }
      

      
    },
    checkTitleField(title){
      this.isTitleError = false;
      this.titleErrorMessage = ''
      if(title.length == 0){
        this.isTitleError = true;
        this.titleErrorMessage = 'To pole jest wymagane.'
      }
    },
    checkDescriptionField(description){
      this.isDescriptionError = false;
      this.descriptionErrorMessage = ''
      if(description.length == 0){
        this.isDescriptionError = true;
        this.descriptionErrorMessage = 'To pole jest wymagane.'
      }
    },
    goToFinal() {
      this.$router.push('/final');
    },
    goBackAll() {
      this.$router.push('/');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #DFEBD6;
  overflow: auto;
  width:405px;
  height:771px;
}
.v-text-field--outlined >>> fieldset {
  border-color: rgba(0, 0, 0, 0.986);
  border-radius:12px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  margin:2%;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}

</style>
<style>

.v-text-field--outlined .v-label--active{
  transform:  translateY(-24px) scale(0.9);
  width:250px;
  letter-spacing:0.05em;
}
.v-textarea .v-label--active{
  transform:  translateY(-24px) scale(0.85);
  width:320px!important;
}

.v-text-field input {
 
 color: black!important;
 letter-spacing: 1%;
}
.v-textarea textarea {
 
 color: black!important;
 letter-spacing: 1%;
}

.v-text-field input::placeholder {
 
 color: black!important;
 letter-spacing: 1%;
}
.v-textarea textarea::placeholder {
 
 color: black!important;
 letter-spacing: 1%;
}

.custom-label-color .v-label {
 color: black!important;
 letter-spacing: 1%;
}
</style>
