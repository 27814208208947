<template>
  <v-container class="przyjemno-screen">
    <v-row >
      <v-col cols="12">
        <h1 class="przyjemno-text" style="font-weight:550; margin-top:10%; font-size: 26px;">
          Czy chcesz dodać 
          <br>swój pomysł przyjemności 
          <br>do wspólnej biblioteki?
        </h1>
      </v-col>
      <v-col cols="12" style="margin-left:auto; margin-right:auto; margin-top:5%;">
        <v-img 
              :src="require('../assets/icons/cloud_white.svg')"
              style="width:50%; margin-left:auto; margin-right:auto;"
        >
          <div class="przyjemno-text" v-if="pleasuresNum!=0" style="font-size:25px; margin-top:30%;">
              {{pleasuresNum}}
          </div>
        </v-img>

        <p class="przyjemno-text" style="font-size: 20px; margin-top:-5%;">
            Tyle osób znalazło tutaj 
            <br>swoje przyjemności.
        </p>

      </v-col>
        <v-col
            cols="12"
            style="margin-left:auto; margin-right:auto; margin-top:25%; text-align:center; "
        >

            <v-row>
                <v-btn height='50px' class="przyjemno-button" style="margin:auto; background:#F5F5F5;" @click="goToAddPleasureForm">
                Dodaję
                </v-btn>
            </v-row>
            <v-row>
                <v-btn height='50px' class="przyjemno-button" style="margin:auto; margin-top:8%; background:#E9F1E2!important;" @click="goToFinalScreen">
                Nie teraz
                </v-btn>
            </v-row>
            <v-row>
                <p class="przyjemno-text" style="margin:auto; margin-top:25%; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>
            </v-row>
        </v-col>
        
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: 'AddPleasurePrompt',
  data: () => ({
    pleasuresNum: 0,
    goBackButton:"<< wracam do początku"
  }),
  methods: {
    goToAddPleasureForm() {
      this.$router.push('/add-pleasure');
    },
    goToFinalScreen() {
      this.$router.push('/final');
    },
    goBackAll() {
      this.$router.push('/');
    },
  },
  mounted () {
    axios
      .get('https://fmaprzyjemnoapp.herokuapp.com/pleasures/count/')
      .then(response => (this.pleasuresNum = response.data.count))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #DFEBD6;
  overflow: auto;
  height: 100%;
  width:405px;
  height:771px;
}

.przyjemno-img-1{
  position:absolute;
  float:right !important;
  object-position: top right;
  margin-right: -30%!important;
  margin-top: -20%!important; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: regular;
  font-size: 4.5vw;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-text-waw{
  font-family: 'League Spartan';
  font-weight: 500;
  text-align: center;
  color: #000000;
  font-size:2.75vw; 
  text-align: left; 
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
</style>
