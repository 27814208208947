<template>
  <v-container class="przyjemno-screen" style="height:100%;">
    <v-row
    align="center"
    justify="center"
    style="margin-top:15%; margin-bottom:5%;">   
        <v-col cols="6">
          <v-img
            :src="require('../assets/icons/logo5.svg')"
          />
        </v-col>
    </v-row>
    <v-row style="">
      <v-col>
        <h1 class="przyjemno-text" style="font-weight:550; font-size:26px; margin-top:0px;">
          Dziękujemy!
          <br>Współtworzysz z nami
          <br>bibliotekę przyjemności
        </h1>
      </v-col>
    </v-row>

    
    <v-layout style="margin-top:20%;" justify-center>
        <v-btn height='50px' class="przyjemno-button" @click="goToEnd">
        Dalej
        </v-btn>
    </v-layout>

    <div class="d-flex">
      <v-img
          :src="require('../assets/icons/4.ilu.svg')"
          style="margin-top:30px;"
      />
    </div>

   
  </v-container>
</template>

<script>
export default {
  name: 'SuccessfulyAdded',
  data: () => ({

  }),
  methods: {
    goToEnd() {
      this.$router.push('/final');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-image{
  transform: scaleY(-1) !important; 
  transform: scaleX(-1);
}
.przyjemno-screen{
  background: #DFEBD6;
  overflow: auto;
  width:405px;
  height:771px;
}

.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 5.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 26px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  margin:5%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
</style>
