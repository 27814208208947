<template>
  <v-container class="przyjemno-screen">
    <v-row>
      <v-col>
        <h1 class="przyjemno-text" style="font-weight:550; margin-top:10%; font-size: 26px;">
        Co możesz zrobić
        <br>z wybraną przyjemnością?
        </h1>


        <v-expansion-panels>
          <v-expansion-panel
          class='przyjemno-panel'
          active-class="przyjemno-panel"
          style="margin-top:8%;"
          @change="() => { changeExpansionStatus()}"
          >
              <v-expansion-panel-header hide-actions>
                <template>
                  <v-row no-gutters>
                    <v-col cols="11">
                      <p style="font-weight:550; font-size:24px; margin-bottom:0;">{{pleasure.title}}</p>
                      <br>
                      <span class="header">{{ !pleasure.expanded ? 'Czytaj więcej' : 'Czytaj mniej' }}</span>
                      <v-icon v-if="!pleasure.expanded">mdi-chevron-down</v-icon>
                      <v-icon v-else>mdi-chevron-up</v-icon>
                    </v-col>
                  </v-row>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content style="text-align: justify;">
                  {{pleasure.description}}
              </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-img
          style="margin-top:8%;"
          :src="require('../assets/icons/pleasureActions.svg')"
        />

        <v-btn height='50px' style="margin-top:20%;" class="przyjemno-button" @click="goToBreathingScreen">
        Dalej
        </v-btn>


        <p class="przyjemno-text" style="margin-top:4%; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>
      </v-col>
        
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PleasureActions',
  data: () => ({
    pleasureChosen:-1,
    pleasure:{},
    goBackButton:"<< wracam do początku"
  }),
  methods: {
    goToBreathingScreen() {
      this.$router.push('/breathe');
    },
    changeExpansionStatus(){
      this.pleasure.expanded = !this.pleasure.expanded;          
      this.$forceUpdate();
    },
    goBackAll() {
      this.$router.push('/');
    },
  },
  mounted () {
    this.pleasure = JSON.parse(localStorage.getItem('pleasureChosen'))
    this.pleasure.expanded = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.icon {
    order: 0;
}

.header {
    order: 1;
}
.przyjemno-screen{
  background: #DFD9EB;
  overflow: auto;
  width:405px;
  height:771px;
}
.przyjemno-panel{
  margin-bottom:10%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.przyjemno-panel:active{
  margin-bottom:10%;
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.v-expansion-panel>>> .v-expansion-panel__wrap {
  background: #FFFFFF;
  border: 1px solid #000000;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 24px;
}

.przyjemno-img-1{
  margin-right: 25%;
  margin-left: 25%; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 410px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-text-waw{
  font-family: 'League Spartan';
  font-weight: 500;
  text-align: center;
  color: #000000;
  font-size:2.75vw; 
  text-align: left; 
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 410px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
</style>
