<template>
  <v-container class="fill-height przyjemno-screen " style="padding:0;">
    <v-img
      :src="require('../assets/icons/shapeFinal.svg')"
      style="margin-right:0%;"
    />
    <v-col style="margin-top:-15%;"> 
        <v-img
          :src="require('../assets/icons/logo5.svg')"
          width="50%"
          style="margin-left:25%; margin-right:25%;"
        />

        <h1 class="przyjemno-text" style="font-weight:550; font-size:26px; margin-top:8%;">
            Zaglądaj do nas,
            <br>kiedy masz potrzebę
        </h1>

        <p class="przyjemno-text" style="margin-top:10%; margin-bottom:5%; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>

        <!-- <v-divider style="border-color:black;"></v-divider> -->

        <v-img
          :src="require('../assets/icons/Line2.svg')"
          style="margin-top:3%; width:60%; margin-left: auto; margin-right: auto;"
        />

        <p class="przyjemno-text" style="margin-top:10%; font-size:14px">
            Aplikację zaprojektowała
            <br>drużyna design.lab w ramach
            <br>Festiwalu Myśli Abstrakcyjnej
            <br><a style="color: black; font-weight:bold; text-decoration: underline;" href="https://fma.waw.pl/przyjemno/">Przeczytaj o nas</a>
        </p>

        <v-img
          :src="require('../assets/icons/logo_festiwal.svg')"
          width="50%"
          style="margin-top:15%; margin-left:25%; margin-right:25%;"
        />
        <v-row style="margin-top:3%; margin-left:25%; margin-right:20%;">
        <v-col cols="6" style="padding:0px; padding-top:24px;">
        <p class="przyjemno-text-waw">
          Projekt 
          <br>współfinansuje
          <br>m. st. Warszawa
        </p>
        </v-col>
        <v-col cols="6">
        <v-img
          :src="require('../assets/icons/logo_wawa.svg')"
          width="100%"
        />
        </v-col>
        </v-row>

    </v-col>
  </v-container>
</template>

<script>
export default {
  name: 'FinalScreen',
  data: () => ({
    goBackButton:"<< wracam do początku"
  }),
  methods: {
    goToAddPleasureForm() {
      this.$router.push('/add-pleasure');
    },
    goToQuestions() {
      this.$router.push('/question-main');
    },
    goBackAll() {
      this.$router.push('/');
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #C6B9D6;
  overflow: auto;
  width:405px;
  height:771px;
}

.przyjemno-img-1{
  position:absolute;
  float:right !important;
  object-position: top right;
  margin-right: -30%!important;
  margin-top: -20%!important; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 20px;
  }
}
.przyjemno-text-waw{
  font-family: 'League Spartan';
  font-weight: 500;
  text-align: center;
  color: #000000;
  font-size:2.75vw; 
  text-align: left; 
}
@media screen and (min-width: 400px) {
  .przyjemno-text-waw {
     font-size: 12px;
  }
}
.przyjemno-button{
  font-family: 'Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}
</style>
