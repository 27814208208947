<template>
  <v-container fluid class="przyjemno-screen" style="overflow: hidden; padding:0px;">
      <v-col cols="12" style="padding:0px;">
        <v-img
          :src="require('../assets/icons/logo01.svg')"
          class="przyjemno-image"
          style="padding:0px;"
        />
      </v-col>

      <v-col style="padding:0px;">
        <p class="przyjemno-text" style="font-size:19px; font-weight: regular;">
          aby połączyć się
          <br>ze sobą i z innymi
        </p>
      </v-col>

      <v-col
        cols="12"
      >
        <v-btn color="#E9E4F1" height='50px' class="przyjemno-button text-capitalize" @click="goForward">
        Wchodzę >>
        </v-btn>
      </v-col>

      <v-col
        cols="12"
      >
        <v-img
          :src="require('../assets/icons/1.ilu.svg')"
          class="przyjemno-image"
          style="position: absolute;bottom: 0;"
        />
      </v-col>
  </v-container>
</template>

<script>

export default {

  name: 'StartingScreen',

  data: () => ({

  }),
  methods: {
    goForward() {
      this.$router.push('/begin');
    }
  },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  overflow: auto;
  width:405px;
  height:771px;
  max-height:100%;
}

.przyjemno-image{
  max-width:100%;
  height:auto;
}

.przyjemno-ksztalt{
  transform: scaleY(-1) !important; 
  transform: scaleX(-1);
  max-height: 400px;
  max-width:200%;
  width:120%;
  /* margin-right:-20% !important;
  margin-bottom:-40% !important; */
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-style: normal;
  opacity: 0.84;
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 1%;
  text-align: center;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 18px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: 600;
  font-size: 20px!important;
  background: #E9E4F1;
  width: 75%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 20%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}

</style>
