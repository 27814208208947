<template style="padding:0;">
  <v-container class="przyjemno-screen fill-height" v-if="!isSearching">
      <v-col cols="12">
        <p class="przyjemno-text" style="font-weight:regular; font-size:17px; letter-spacing:1%;">
            Odpowiedz na pytanie,
            <br>aby przejść do kolejnego
        </p>
      </v-col>
      <v-col cols="12">
        <v-img
          style="margin-top:10%"
          :src="require('../assets/icons/question'+ID+'.svg')"
        />
        
      </v-col>

      <v-row style="margin-top:20%;">
        <v-col>
          <v-btn height='50px' class="przyjemno-button" @click="() => goToQuestions(-1)">
          Nie
          </v-btn>   
        </v-col>
        <v-col>     
          <v-btn height='50px' class="przyjemno-button" @click="() => goToQuestions(1)">
          Tak
          </v-btn>
        </v-col>
      </v-row>

      <v-col
      class="mb-5"
      cols="12"
      >
        <v-btn height='50px' class="przyjemno-button" style="width:40%; background:#E8E3F2!important;" @click="() => goToQuestions(0)">
        Obojętnie
        </v-btn>
      </v-col>
      <v-col
        cols="6"
        style="margin-left:auto;margin-right:auto;"
      >
        <v-img
          :src="require('../assets/icons/kropki'+ID+'.svg')"
          style="width:100%;"
        />
      </v-col>
      <v-col cols="12">
        <p class="przyjemno-text" style="margin-top:15%; font-size: 17px; font-weight:regular; letter spacing 1%;" @click="goBackAll" v-text="goBackButton"></p>
      </v-col>
  </v-container>
  <v-container v-else class="przyjemno-screen" style="position:relative; background:#FFFFFF;padding:0;">

    <v-col cols="12">
      <v-img
        :src="require('../assets/icons/ksztalt1.svg')"
      />
    </v-col>

    <v-col>
      <h1 class="przyjemno-text" style="font-weight:500; font-size: 26px">
      Szukamy dla Ciebie
      <br>przyjemności
      </h1>
    </v-col>

    <div class="spinner" style="margin-top:5%;">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>

    <v-col>
      <p class="przyjemno-text" style="margin-top:20%;">
        W naszej bibliotece
        <br>mamy ich już
      </p>
    </v-col>
    <v-col cols="6" style="margin-left:auto; margin-right:auto;">
      <v-img 
            :src="require('../assets/icons/cloud_gray.svg')"
            style=""
      >
        <div class="przyjemno-text" v-if="pleasuresNum!=0" style="font-size:25px; margin-top:15%;">
            {{pleasuresNum}}
        </div>
      </v-img>

    </v-col>

</v-container>
</template>

<script>
import axios from 'axios';
export default {
  name: 'QuestionForm',
  data: () => ({
    question:'',
    goBackButton:"<< wracam do początku",
    ID:1,
    pleasuresNum:0,
    pleasuresList:[],
    isSearching:false,
    scoring:{
      stay_at_home:0,
      other_people:0,
      body_oriented:0,
      intellectual:0,
      silent:0
    },
    questions:{
      '1':"Chcesz zostać w domu?",
      '2':"Chcesz zrobić coś z innymi ludźmi?",
      '3':"Chcesz poczuć swoje ciało?",
      '4':"Potrzebujesz intelektualnego wyzwania?",
      '5':"Potrzebujesz się wyciszyć?"
    }
  }),
  methods: {
    goToAddPleasureForm() {
      this.$router.push('/add-pleasure');
    },
    goToQuestions(ans) {
      this.question = this.ID;
      switch(this.question) {
      case 1:
        this.question = "stay_at_home";
        break;
      case 2:
        this.question = "other_people";
        break;
      case 3:
        this.question = "body_oriented";
        break;
      case 4:
        this.question = "intellectual";
        break;
      case 5:
        this.question = "silent";
        break;
    }
      this.scoring[this.question] = ans;
      if (this.ID < 5){
        this.ID += 1;
      }
      else{
        this.isSearching = true;
        
        this.link = 'https://fmaprzyjemnoapp.herokuapp.com/pleasures/'
        
        if(this.scoring['stay_at_home'] != 0){
          this.link +='?stay_at_home='
          this.link += this.scoring['stay_at_home']
        }
        
        if(this.scoring['other_people'] != 0){
          this.link +='&other_people='
          this.link += this.scoring['other_people']
        }

        if(this.scoring['body_oriented'] != 0){
          this.link +='&body_oriented='
          this.link += this.scoring['body_oriented']
        }

        if(this.scoring['intellectual'] != 0){
          this.link +='&intellectual='
          this.link += this.scoring['intellectual']
        }

        if(this.scoring['silent'] != 0){
          this.link +='&silent='
          this.link += this.scoring['silent']
        }
        
        axios
        .get('https://fmaprzyjemnoapp.herokuapp.com/pleasures/count/')
        .then(response => (this.pleasuresNum = response.data.count))
        .catch(error => console.log(error))
      
        axios
        .get(this.link)
        .then(response => {
          this.pleasuresList = this.pleasuresToList(response);
          this.$nextTick(()=>{this.$router.push('/result')})
        })
        .catch(error => console.log(error))
        
      }
    },
    goBackAll() {
      this.$router.push('/');
    },
    pleasuresToList(response){
      this.pleasuresList = []
  
      this.pleasuresList.push(response.data[0])
      this.pleasuresList.push(response.data[1])
      localStorage.clear()
      localStorage.setItem('pleasureList',JSON.stringify(this.pleasuresList))
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #DFD9EB;
  overflow: auto;
  width:405px;
  height:771px;
}

.przyjemno-img-1{
  position:absolute;
  float:right !important;
  object-position: top right;
  margin-right: -30%!important;
  margin-top: -20%!important; 
}
.przyjemno-img-2{
  position:relative;
  top:100px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: 500;
  font-size: 4.5vw;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 22px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  background: #FFFFFF!important;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  margin:5%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}

.spinner {
  margin: 100px auto 0;
  width: 80px;
  text-align: center;
}

.spinner > div {
  width: 12px;
  height: 12px;
  margin-left:5%;
  margin-right:5%;
  background-color: #333;

  border-radius: 100%;
  display: inline-block;
  -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
 @-webkit-keyframes sk-bouncedelay {
  0%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}
</style>
