<template>
  <v-container class="przyjemno-screen">
    <v-row>
        <v-col style="margin-top:25%;">
            <p class="przyjemno-text">
            Po znalezieniu przyjemności
            <br>weź kilka głębokich oddechów
            </p>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" align="center" justify="center" style="margin-top:24%;">
          <!-- <Transition appear name="breath">
                <v-col v-if="show" cols="12" align="center" justify="center" style="margin-top:24%;">
                    <v-img
                    :src="require('../assets/icons/cloud_gray.svg')"
                    contain
                    width="50%"
                    />
                </v-col>
          </Transition> -->

          <div class="spinner">
              <v-img
              :src="require('../assets/icons/cloud_gray.svg')"
              contain
              width="50%"
              class="bounce1"
              />
          </div>
      </v-col>
    </v-row>
    <v-row>
        <v-col cols="12">
          <p class="przyjemno-text" style="margin-top:40%;" align="center" justify="center">
          Daj nam znać jak skończysz
          </p>
          
          <v-btn height='50px' class="przyjemno-button" @click="goToAddPrompt" style="margin-top:10%;">
          Już
          </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'BreathingScreen',
  data: () => ({
    show: true,
  }),
  methods: {
    goToAddPrompt() {
      this.$router.push('/add-prompt');
    }
  },
  created () {
    setInterval(() => {
      this.show = !this.show
    }, 2000)
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=League+Spartan:wght@500&family=Libre+Baskerville&display=swap');
.przyjemno-screen{
  background: #FFFFFF;
  overflow: auto;
  width:405px;
  height:771px;
}
.przyjemno-text{
  font-family: 'League Spartan';
  font-weight: regular;
  font-size: 19px;
  line-height: 26px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
}
@media screen and (min-width: 400px) {
  .przyjemno-text {
     font-size: 19px;
  }
}
.przyjemno-button{
  font-family: 'League Spartan';
  font-weight: bold;
  font-size: 4vw;
  width: 66%;
  min-width: 0;
  min-height: 0;
  padding: 0;
  height: 10%;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
}
@media screen and (min-width: 400px) {
  .przyjemno-button {
     font-size: 14px;
  }
}

/* .breath-enter-active,
.breath-leave-active{
  animation: bounce-in 2.5s;
}
.breath-enter-from,
.breath-leave-to  {
  animation: bounce-in 3s reverse;
}
@keyframes bounce-in {
  75% {
    transform: scale(1.25);
  }
  125% {
    transform: scale(1);
  }
} */

.spinner > div {

  -webkit-animation: sk-bouncedelay 6s infinite alternate both;
  animation: sk-bouncedelay 6s infinite alternate both;
}

.spinner .bounce1 {
  -webkit-animation-delay: -0.25s;
  animation-delay: -0.25s;
}
 @-webkit-keyframes sk-bouncedelay {
  40%, 100% { -webkit-transform: scale(0.7) }
  60% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  40%, 100% { 
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  } 60% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}


</style>
