import Vue from 'vue'
import VueRouter from 'vue-router'
import StartingScreen from '../components/StartingScreen.vue'
import BeginningScreen from '../components/BeginningScreen.vue'
import AddPleasure from '../components/AddPleasure.vue'
import QuestionMainScreen from '../components/QuestionMainScreen.vue'
import FinalScreen from '../components/FinalScreen.vue'
import SuccessfullyAdded from '../components/SuccessfullyAdded.vue'
import QuestionForm from '../components/QuestionForm.vue'
import SearchPleasure from '../components/SearchPleasure.vue'
import PleasureScreen from '../components/PleasureScreen.vue'
import PleasureActions from '../components/PleasureActions.vue'
import BreathingScreen from '../components/BreathingScreen.vue'
import AddPleasurePrompt from '../components/AddPleasurePrompt.vue'

const routes = [
  {
    path: '',
    name: 'home',
    component: StartingScreen
  },
  {
    path: '/begin',
    name: 'beginning',
    component: BeginningScreen
  },
  {
    path: '/add-pleasure',
    name: 'addPleasure',
    component: AddPleasure
  },
  {
    path: '/question-main',
    name: 'questionMain',
    component: QuestionMainScreen
  },
  {
    path: '/final',
    name: 'finalScreen',
    component: FinalScreen
  },
  {
    path: '/success-add',
    name: 'successfullyAdded',
    component: SuccessfullyAdded
  },
  {
    path: '/question',
    name: 'question',
    component: QuestionForm
  },
  {
    path: '/search',
    name: 'search',
    component: SearchPleasure
  },
  {
    path: '/result',
    name: 'Result',
    component: PleasureScreen
  },
  {
    path: '/actions',
    name: 'Actions',
    component: PleasureActions
  },
  {
    path: '/breathe',
    name: 'Breathing',
    component: BreathingScreen
  },
  {
    path: '/add-prompt',
    name: 'AddPrompt',
    component: AddPleasurePrompt
  },

]

Vue.use(VueRouter)

const router = new VueRouter({
  base: "",
  routes: routes
})

export default router
